exports.components = {
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-customers-participant-[id]-js": () => import("./../../../src/pages/customers/participant/[id].js" /* webpackChunkName: "component---src-pages-customers-participant-[id]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logbook-[id]-js": () => import("./../../../src/pages/logbook/[id].js" /* webpackChunkName: "component---src-pages-logbook-[id]-js" */),
  "component---src-pages-logbook-add-log-js": () => import("./../../../src/pages/logbook/addLog.js" /* webpackChunkName: "component---src-pages-logbook-add-log-js" */),
  "component---src-pages-logbook-index-js": () => import("./../../../src/pages/logbook/index.js" /* webpackChunkName: "component---src-pages-logbook-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

